.custom_switch {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    width: 64px;
    height: 30px;
    cursor: pointer;
    border: 1px solid #dceae8;
    border-radius: 20px;
}

.custom_switch input[type="checkbox"] {
    position: absolute;
    opacity: 0;
}

.custom_switch_icon {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 36px;
    height: 100%;
    background: #e8f4f3;
    background-size: 13px;
    border-radius: 20px;
    transition: transform 120ms ease-out;
}

.custom_switch_icon:after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 13px;
    height: 17px;
    margin: -9px 0 0 -6px;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iMTciIHZpZXdCb3g9IjAgMCAxMyAxNyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTIuMDc3OCA2Ljg2NTMxSDExLjc0ODJWNS4zMTQ5OEMxMS43NDgyIDIuNDQ3NTMgOS40ODQyNSAwLjA1MjE4MDYgNi42NTQ1NyAwLjAwMTA0NzhDNi41NzcyNSAtMC4wMDAzNDkyNjcgNi40MjI3NSAtMC4wMDAzNDkyNjcgNi4zNDU0NyAwLjAwMTA0NzhDMy41MTU3MSAwLjA1MjE4MDYgMS4yNTE3OCAyLjQ0NzUzIDEuMjUxNzggNS4zMTQ5OFY2Ljg2NTMxSDAuOTIyMTgyQzAuNDE0Mjg0IDYuODY1MzEgMCA3LjM5MjA3IDAgOC4wNDI2NVYxNS44MTg1QzAgMTYuNDY4MyAwLjQxNDI4NCAxNyAwLjkyMjIxNyAxN0gxMi4wNzc4QzEyLjU4NTcgMTcgMTMgMTYuNDY4MyAxMyAxNS44MTg1VjguMDQyNjVDMTMgNy4zOTIxMSAxMi41ODU3IDYuODY1MzEgMTIuMDc3OCA2Ljg2NTMxWk03LjU0NTEzIDExLjkyMjdWMTQuMjcyMkM3LjU0NTEzIDE0LjU0MTMgNy4zMjEwMyAxNC43Njg5IDcuMDUzMTQgMTQuNzY4OUg1Ljk0NjkzQzUuNjc5MDEgMTQuNzY4OSA1LjQ1NDkxIDE0LjU0MTMgNS40NTQ5MSAxNC4yNzIyVjExLjkyMjdDNS4xOTUwOSAxMS42NjU2IDUuMDQzODIgMTEuMzEwMiA1LjA0MzgyIDEwLjkxNjlDNS4wNDM4MiAxMC4xNzE4IDUuNjE2ODggOS41MzE1NCA2LjM0NTUgOS41MDE5NkM2LjQyMjcyIDkuNDk4ODIgNi41NzczOSA5LjQ5ODgyIDYuNjU0NiA5LjUwMTk2QzcuMzgzMjMgOS41MzE1NCA3Ljk1NjI4IDEwLjE3MTggNy45NTYyOCAxMC45MTY5QzcuOTU2MjEgMTEuMzEwMiA3LjgwNDk1IDExLjY2NTYgNy41NDUxMyAxMS45MjI3Wk05LjU3NjAzIDYuODY1MzFINi42NTQ1N0g2LjM0NTQ3SDMuNDI0MDFWNS4zMTQ5OEMzLjQyNDAxIDMuNjA2ODkgNC44MDUwOSAyLjE5NDU1IDYuNSAyLjE5NDU1QzguMTk0OTEgMi4xOTQ1NSA5LjU3NjA2IDMuNjA2ODkgOS41NzYwNiA1LjMxNDk4VjYuODY1MzFIOS41NzYwM1oiIGZpbGw9IiMyN0I4QTUiLz48L3N2Zz4=);
}

.custom_switch input[type="checkbox"]:checked + .custom_switch_icon {
    transform: translateX(-26px);
}

.custom_switch input[type="checkbox"]:checked + .custom_switch_icon:after {
    filter: hue-rotate(180deg) saturate(10);
}