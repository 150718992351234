.custom_select {
    width: 100%;
    height: 42px;
    padding: 0 10px;
    font-family: $font_family;
    font-size: 14px;
    background: #fff;
    border: 1px solid #ccc;
    transition: border-color 90ms ease-out;
}

.custom_select:focus {
    border-color: #1d8e7f;
}

/* ------------------------------------------------------- */

.multiselect__tags {
    border-color: #ccc;
}

.multiselect__content-wrapper {
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
}

.multiselect__option {
    overflow: hidden;
    text-overflow: ellipsis;
}

.multiselect__option--highlight,
.multiselect__option--selected.multiselect__option--highlight {
    background: #27b8a5;
}

.multiselect__option--selected {
    font-weight: 500;
}

.multiselect__input {
    padding: 0;
    font-size: 14px;
}