.oz_media_list {
    display: block;
    position: relative;
    margin: -15px;
}

.oz_media_list_cell {
    display: inline-block;
    vertical-align: top;
    width: 25%;
    min-width: 150px;
    padding: 15px;
    transition: opacity 300ms ease-out, transform 300ms ease-out;
}

.oz_media_list_cell._toggle-leave-active {
    position: absolute;
}

.oz_media_list_cell._toggle-enter, 
.oz_media_list_cell._toggle-leave-to {
    opacity: 0;
    transform: scale(0.6);
}

.oz_media_item {
    display: block;
    position: relative;
}

.oz_media_item_delete {
    z-index: 1;
    display: block;
    position: absolute;
    top: -14px;
    right: -14px;
    width: 30px;
    height: 30px;
    cursor: pointer;
    background: #fff;
    border: 1px solid #c7dbd8;
    border-radius: 100%;
}

.oz_media_item_delete:before,
.oz_media_item_delete:after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20px;
    height: 2px;
    margin: -1px 0 0 -10px;
    background: $link_color;
    transform-origin: 50% 50%;
}

.oz_media_item_delete:before {
    transform: rotate(-45deg);
}

.oz_media_item_delete:after {
    transform: rotate(45deg);
}

.oz_media_item_delete:hover:before,
.oz_media_item_delete:hover:after,
.oz_media_item_delete._hover:before,
.oz_media_item_delete._hover:after {
    background: #f00;
}

.oz_media_item_download {
    z-index: 1;
    display: block;
    position: absolute;
    bottom: -14px;
    left: 50%;
    width: 30px;
    height: 30px;
    margin: 0 0 0 -15px;
    cursor: pointer;
    background-color: #fff;
    border: 1px solid #c7dbd8;
    border-radius: 100%;

    &:after {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 14px;
        height: 20px;
        margin: -10px 0 0 -7px;
        background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAxMCAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNOC43NzQzOCA4LjUwMTk5QzkuMDU5NDggOC4yMTcxOCA5LjUwODMxIDguMjE3MTggOS43OTM0IDguNTAxOTlDMTAuMDY4OSA4Ljc4NjggMTAuMDY4OSA5LjIzNTE5IDkuNzkzNCA5LjUyTDUuNTIzNCAxMy43ODY0QzUuMzkxMTIgMTMuOTE4NSA1LjIwNzQ4IDE0IDUuMDAzOTMgMTRDNC44MDAzOSAxNCA0LjYxNjc0IDEzLjkxODUgNC40ODM4MyAxMy43ODY0TDAuMjEzODIxIDkuNTJDLTAuMDcxMjczOCA5LjIzNTE5IC0wLjA3MTI3MzggOC43ODY4IDAuMjEzODIxIDguNTAxOTlDMC40OTg5MTYgOC4yMTcxOCAwLjk0Nzc0OSA4LjIxNzE4IDEuMjMyODQgOC41MDE5OUw0LjI4MDI4IDExLjU1NjdWMC43MTI2NjlDNC4yNzk2NCAwLjMxNTYwMSA0LjYwNTgzIDAgNS4wMDMyOSAwQzUuNDAwNzUgMCA1LjcxNjY3IDAuMzE1NjAxIDUuNzE2NjcgMC43MTI2NjlWMTEuNTU2TDguNzc0MzggOC41MDE5OVoiIGZpbGw9IiMyN0I4QTUiLz48L3N2Zz4=);
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: contain;
    }

    &:hover:after {
        filter: brightness(1.1);
    }

    &:active:after {
        transform: translateY(2px);
    }
}

.oz_media_item_thumbnail {
    display: block;
    position: relative;
    padding: 100% 0 0 0;
    background-color: #fff;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: contain;
    border: 1px solid #c7dbd8;
    transition: opacity 120ms ease-out;

    &._delete {
        opacity: 0.3;
    }

    &._clickable {
        cursor: pointer;

        &:after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            background: url('../img/zoom.svg') no-repeat 50% 50%;
            background-size: 25%;
            transition: opacity 60ms ease-out;
        }

        &:hover:after {
            opacity: 1;
        }
    }

    &:not([style*="background-image"]) {
        background-color: #e5e5e5;
        background-image: url('../img/media.svg');
        background-size: 64px;
    }
}

.oz_media_item_thumbnail_ext {
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 10px;
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    text-transform: uppercase;
    color: #777;
}

.oz_media_item_type {
    margin: 10px 0 0 0;
}