.plain_list {
    display: block;
}

.plain_list_cell {
    display: flex;
    align-items: center;
    padding: 6px 12px;
    border-top: 1px solid #eee;
    
    &:first-child { border-top: none; }
    
    &:hover,
    &._hover { background: #f7f9f8; }
}

.plain_list_img {
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    margin: -6px 12px -6px -12px;

    a {
        display: block;
        margin: 0 auto;
    }

    img {
        display: block;
        width: 100%;
        max-width: 60px;
        max-height: 43px;
    }
}

.plain_list_img img {
    
}

.plain_list_title {
    flex: 1 1 auto;
}

.plain_list_title_main {
    display: block;
}

.plain_list_title_sub {
    margin: 4px 0 0 0;
    font-size: 13px;
    color: #777;
}

.plain_list_title_sub._error {
    font-size: 12px;
    color: #c55;
}

.plain_list_control {
    flex: 0 0 auto;
    margin: 0 0 0 auto;
}

.plain_list_button {
    flex: 0 0 auto;
    margin: 0 0 0 6px;
}

.plain_list_empty {
    margin: 17px 0;
    padding: 0 12px;
    color: #777;
}