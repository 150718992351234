.back_button {
    display: inline-block;
    position: relative;
    height: 24px;
    line-height: 24px;
    margin: 0;
    padding: 0 0 0 22px;
    font-family: $font_family;
    font-size: 20px;
    font-weight: 500;
    white-space: nowrap;
    cursor: pointer;
    color: $link_color;
    background: none;
    outline: none;
    border: none;
    border-radius: 0;
    box-shadow: none;

    &:hover {
        color: $hover_color;
    }
    
    &:before {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 0;
        width: 14px;
        height: 14px;
        margin: -5px 0 0 0;
        background-repeat: no-repeat;
        background-position: 0 0;
        background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTUiIGhlaWdodD0iMTMiIHZpZXdCb3g9IjAgMCAxNSAxMyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMC4yNTk4NzMgNS40ODM2NEw1LjU2MjE5IDAuMjUxMDdDNS45MDQwNiAtMC4wODczMDg4IDYuNDU2OTQgLTAuMDgyOTIwMiA2Ljc5NTMyIDAuMjU5ODA3QzcuMTMzNyAwLjYwMjUzMyA3LjEzMDIxIDEuMTU0NTYgNi43ODY1OSAxLjQ5Mjk0TDIuOTk3MzcgNS4yMzI0NkgxMy4xNTExQzEzLjYzMzQgNS4yMzI0NiAxNC4wMjMyIDUuNjIyMjcgMTQuMDIzMiA2LjEwNDU0QzE0LjAyMzIgNi41ODY4IDEzLjYzMzQgNi45NzY2NSAxMy4xNTExIDYuOTc2NjVIMi45OTczN0w2Ljc4NjU5IDEwLjcxNjJDNy4xMzAxNyAxMS4wNTQ2IDcuMTMyOCAxMS42MDY2IDYuNzk1MzIgMTEuOTQ5M0M2LjYyNDQxIDEyLjEyMiA2LjM5OTQgMTIuMjA5MiA2LjE3NDM5IDEyLjIwOTJDNS45NTI4NiAxMi4yMDkyIDUuNzMxMzggMTIuMTI1NSA1LjU2MjE5IDExLjk1OEwwLjI1OTg3MyA2LjcyNTQzQzAuMDk0MTcxNSA2LjU2MTQ5IDAgNi4zMzgyIDAgNi4xMDQ1QzAgNS44NzA4NyAwLjA5MzMxMDQgNS42NDg0OCAwLjI1OTg3MyA1LjQ4MzY0WiIgZmlsbD0iIzI3QjhBNSIvPjwvc3ZnPg==);
        background-size: contain;
    }

    &:hover:before {
        filter: brightness(1.1);
    }
}