$cell_width: 8.33%;
$cell_margin: 24px;

.custom_form {
    display: block;
}

.custom_form_row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 0 0 -#{$cell_margin};
}

.custom_form_row.short_row .custom_form_cell {
    margin-bottom: 12px !important;
}

.custom_form_cell {
    width: calc(100% - #{$cell_margin});
    margin: 0 0 34px $cell_margin;
}

@for $i from 1 through 12 {
    .custom_form_cell._#{$i}_12 {
        width: calc(#{$cell_width * $i} - #{$cell_margin});
    }
}

.custom_form_cell._half {
    width: calc(50% - #{$cell_margin});
}

.custom_form_cell._third {
    width: calc(33.33% - #{$cell_margin});
}

.custom_form_cell_title {
    font-size: 14px;
    font-weight: 500;
}

.custom_form_cell_value {
    margin: 8px 0 0 0;
    font-size: 14px;
}

.custom_form_clear {
    display: block;
    width: 100%;
    height: 0;
    margin: 0;
    padding: 0;
}