.custom_checkbox {
    position: relative;
    cursor: pointer;
}

.custom_checkbox input[type="checkbox"] {
    position: absolute;
    opacity: 0;
}

.custom_checkbox_text {
    display: inline-block;
    position: relative;
    min-height: 22px;
    padding: 0 0 0 30px;
}

.custom_checkbox_text._empty {
    padding: 0 0 0 22px;
}

.custom_checkbox_text:before {
    content: '';
    display: block;
    position: absolute;
    top: 1px;
    left: 0;
    width: 19px;
    height: 19px;
    background: #fff;
    border: 1px solid #c7dbd8;
    transition: border-color 90ms ease-out;
}

.custom_checkbox_text:after {
    content: '';
    display: block;
    position: absolute;
    top: 6px;
    left: 4px;
    width: 11px;
    height: 6px;
    opacity: 0;
    border-width: 2px 2px 0 0;
    border-style: solid;
    border-color: #27b8a5;
    transform: translateY(-3px) rotate(135deg);
    transition: opacity 90ms ease-out, transform 90ms ease-out;
}

.custom_checkbox input[type="checkbox"]:checked + .custom_checkbox_text:after {
    opacity: 1;
    transform: rotate(135deg);
}

.custom_checkbox:hover .custom_checkbox_text:before,
.custom_checkbox input[type="checkbox"]:checked + .custom_checkbox_text:before {
    border-color: #27b8a5;
}

.custom_checkbox input[type="checkbox"][disabled] + .custom_checkbox_text {
    opacity: 0.5;
}