.login_page {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background: url('../img/login_bg.png') no-repeat 50% 50%, linear-gradient(132deg, #1D8E7F -2%, #27B8A5 40%, #D0F6F1 102%) repeat 50% 50%;
}

.login_page a {
    color: #365283;
}

.login_page a:hover {
    text-decoration: none;
}

.login_page_content {
    position: relative;
    width: 100%;
    max-width: 480px;
    margin: 60px 0 0 0;

    @media (max-height: 500px) {
        margin: 20px 0 0 0;
    }
}

.login_logo {
    display: block;
    position: absolute;
    top: -158px;
    left: 50%;
    width: 248px;
    height: 120px;
    margin: 0 0 0 -124px;
    background: url('../img/login_logo.svg') no-repeat 0 0;
    background-size: contain;

    @media (max-height: 720px) {
        top: calc(-58px - 10vh);
        width: 208px;
        height: 100px;
        margin: 0 0 0 -104px;
    }

    @media (max-height: 570px) {
        top: calc(-10px - 15vh);
        width: 154px;
        height: 74px;
        margin: 0 0 0 -77px;
    }

    @media (max-height: 500px) {
        position: relative;
        top: auto;
        left: auto;
        margin: 0 auto 20px auto;
    }
}

.login_form {
    display: block;
    position: relative;
    padding: 26px 42px 46px 42px;
    background: #fff;
    box-shadow: 0px 26px 60px #1A7C6F;
}

.login_form:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    visibility: hidden;
    background-color: #fff;
    background-image: url('../img/loader.svg');
    background-repeat: no-repeat;
    background-position: 50% 50%;
    border-radius: 0 0 10px 10px;
    transition: opacity 240ms ease-out, visibility 240ms;
}

.login_form._loading:after {
    opacity: 0.7;
    visibility: visible;
}

.login_form_head {
    margin: 0 0 26px 0;
    font-size: 23px;
    text-align: center;
}

.login_form_field {
    margin: 0 0 20px 0;
}

.login_submit_button {
    width: 100%;
    height: 52px;
    line-height: 52px;
    font-family: $font_family;
    font-size: 17px;
    font-weight: 500;
    white-space: nowrap;
    cursor: pointer;
    color: #fff;
    background: #27B8A5;
    border: none;
}

.login_input {
    width: 100%;
    height: 52px;
    padding: 0 15px;
    font-family: $font_family;
    font-size: 14px;
    font-weight: 500;
    border: 1px solid #E5E5E5;
}

.login_input::placeholder {
    color: #777;
}

.login_form_error {
    margin: 10px 0 0 0;
    font-size: 14px;
    color: #b92020;
}

.login_form_other {
    display: flex;
    justify-content: space-between;
    margin: 24px 0 30px 0;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    color: #A8ACAC;
}

.login_form_checkbox {
    position: relative;
    cursor: pointer;
}

.login_form_checkbox input {
    position: absolute;
    opacity: 0;
}

.login_form_checkbox_content {
    display: inline-block;
    position: relative;
    padding: 0 0 0 30px;
}

.login_form_checkbox_content:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    border: 1px solid #C7DBD8;
}

.login_form_checkbox_content:after {
    content: '';
    display: block;
    position: absolute;
    top: 5px;
    left: 5px;
    width: 11px;
    height: 7px;
    opacity: 0;
    border-style: solid;
    border-color: #27B8A5;
    border-width: 2px 2px 0 0;
    transform: translateY(-5px) rotate(135deg);
    transition: transform 120ms ease-out, opacity 120ms ease-out;
}

.login_form_checkbox input:checked + .login_form_checkbox_content:after {
    opacity: 1;
    transform: rotate(135deg);
}