.form_widget {
    display: block;
    padding: 18px;
    background: #f7f9f8;

    &._addon {
        display: none;
        border-top: 1px solid #d3dfde;

        &._open {
            display: block;
        }
    }
}

.form_widget_header {
    display: flex;
    align-items: center;
    margin: -18px 0 0 0;
    padding: 14px 0;
}

.form_widget_header_title {
    font-size: 14px;
    font-weight: 500;
}

.form_widget_header_control {
    display: flex;
    margin-left: auto;
}

.form_widget_body {
    max-height: 177px;
    overflow-y: auto;
    background: #fff;
    border: 1px solid #e5e5e5;
    transition: opacity 150ms ease-out;
}

.form_widget_body._loading {
    opacity: 0.3;
}