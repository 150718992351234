.custom_hint {
    display: inline-block;
    position: absolute;
    width: 18px;
    height: 18px;
    margin: -1px 0 0 5px;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTAgMTUuNzYxN0MxMC41MzkzIDE1Ljc2MTcgMTAuOTc2NiAxNS4zMjQ1IDEwLjk3NjYgMTQuNzg1MkMxMC45NzY2IDE0LjI0NTggMTAuNTM5MyAxMy44MDg2IDEwIDEzLjgwODZDOS40NjA2NiAxMy44MDg2IDkuMDIzNDQgMTQuMjQ1OCA5LjAyMzQ0IDE0Ljc4NTJDOS4wMjM0NCAxNS4zMjQ1IDkuNDYwNjYgMTUuNzYxNyAxMCAxNS43NjE3WiIgZmlsbD0iIzFEOEU3RiIvPjxwYXRoIGQ9Ik0xMCAwQzQuNDczMjggMCAwIDQuNDcyNTQgMCAxMEMwIDE1LjUyNjcgNC40NzI1NCAyMCAxMCAyMEMxNS41MjY3IDIwIDIwIDE1LjUyNzUgMjAgMTBDMjAgNC40NzMyOCAxNS41Mjc1IDAgMTAgMFpNMTAgMTguNDM3NUM1LjMzNjg0IDE4LjQzNzUgMS41NjI1IDE0LjY2MzggMS41NjI1IDEwQzEuNTYyNSA1LjMzNjg0IDUuMzM2MjEgMS41NjI1IDEwIDEuNTYyNUMxNC42NjMyIDEuNTYyNSAxOC40Mzc1IDUuMzM2MjEgMTguNDM3NSAxMEMxOC40Mzc1IDE0LjY2MzIgMTQuNjYzOCAxOC40Mzc1IDEwIDE4LjQzNzVaIiBmaWxsPSIjMUQ4RTdGIi8+PHBhdGggZD0iTTEwIDUuMDE5NTNDOC4yNzY4OCA1LjAxOTUzIDYuODc1IDYuNDIxNDEgNi44NzUgOC4xNDQ1M0M2Ljg3NSA4LjU3NjAyIDcuMjI0NzcgOC45MjU3OCA3LjY1NjI1IDguOTI1NzhDOC4wODc3MyA4LjkyNTc4IDguNDM3NSA4LjU3NjAyIDguNDM3NSA4LjE0NDUzQzguNDM3NSA3LjI4Mjk3IDkuMTM4NDQgNi41ODIwMyAxMCA2LjU4MjAzQzEwLjg2MTYgNi41ODIwMyAxMS41NjI1IDcuMjgyOTcgMTEuNTYyNSA4LjE0NDUzQzExLjU2MjUgOS4wMDYwOSAxMC44NjE2IDkuNzA3MDMgMTAgOS43MDcwM0M5LjU2ODUyIDkuNzA3MDMgOS4yMTg3NSAxMC4wNTY4IDkuMjE4NzUgMTAuNDg4M1YxMi40NDE0QzkuMjE4NzUgMTIuODcyOSA5LjU2ODUyIDEzLjIyMjcgMTAgMTMuMjIyN0MxMC40MzE1IDEzLjIyMjcgMTAuNzgxMiAxMi44NzI5IDEwLjc4MTIgMTIuNDQxNFYxMS4xNzA3QzEyLjEyNzYgMTAuODIyOSAxMy4xMjUgOS41OTgwMSAxMy4xMjUgOC4xNDQ1M0MxMy4xMjUgNi40MjE0MSAxMS43MjMxIDUuMDE5NTMgMTAgNS4wMTk1M1oiIGZpbGw9IiMxRDhFN0YiLz48L3N2Zz4=);
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: contain;
}