.custom_tooltip {
    z-index: 99999;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    margin: 8px 0 0 0;
    padding: 4px 10px 6px 10px;
    cursor: default;
    opacity: 0;
    font-size: 12px;
    line-height: 1.2;
    color: #a8acac;
    background: #fff;
    box-shadow: 0 2px 12px rgba(183, 209, 206, 0.6);
    transform: translateY(-8px);
    transition: opacity 120ms ease-out, transform 120ms ease-out;

    &._visible {
        opacity: 1;
        transform: none;
    }
}