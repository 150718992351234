.oz_filter {
    z-index: 98;
    position: fixed;
    overflow: hidden;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 65px 0 0 0;
    visibility: hidden;
    transition: visibility 300ms;
}

.oz_filter._open {
    visibility: visible;
}

.oz_filter:before {
    z-index: -1;
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    background: #000;
    transition: opacity 300ms ease-out;
}

.oz_filter._open:before {
    opacity: 0.5;
}

.oz_filter_content {
    z-index: 10;
    position: relative;
    padding: 15px 20px 20px 20px;
    background: #fff;
    border-bottom: 1px solid #c7dbd8;
    transition: transform 300ms ease-out;
    transform: translateY(-100%);
}

.oz_filter._open .oz_filter_content {
    transform: none;
}

.oz_filter_grid {
    display: flex;
    flex-wrap: wrap;
    min-width: 100%;
    margin: 0 0 -15px -24px;
}

.oz_filter_cell {
    display: flex;
    flex-direction: column;
    /*justify-content: flex-end;*/
    justify-content: flex-start; /* my */
    padding: 0 0 20px 24px;

    &._control {
        margin-left: auto;
        justify-content: flex-end; /* my */
    }
}

@for $i from 1 through 12 {
    .oz_filter_cell._#{$i}_12 {
        width: #{calc(100% / 12 * $i)};
    }
}

.oz_filter_title {
    margin: 0 0 auto 0;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 2px; /* my */
}

.oz_filter_field {
    margin: 6px 0 0 0;
}