.oz_pager {
    display: flex;
    align-items: baseline;
    justify-content: center;
    padding: 6px 0;
    user-select: none;
    background: #fff;
    box-shadow: 0px 2px 12px rgba(183, 209, 206, 0.6);
}

.oz_pager_item,
.oz_pager_separator {
    padding: 0 4px;
    line-height: 1;
    font-size: 14px;
    text-align: center;
    cursor: pointer;
    color: #a8acac;
    touch-action: manipulation;
}

.oz_pager_item:hover,
.oz_pager_item._selected {
    color: $link_color;
}

.oz_pager_item._distant {
    font-size: 10px;
}

.oz_pager_separator {
    display: none;
    padding: 0;
    cursor: default;
}

.oz_pager_nav {
    position: relative;
    width: 24px;
    height: 10px;
    cursor: pointer;
    touch-action: manipulation;
    transition: opacity 90ms ease-out;
}

.oz_pager_nav._disabled {
    cursor: default;
    opacity: 0.5;
}

.oz_pager_nav:before {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 10px;
    height: 10px;
    margin: -5px 0 0 0;
    border-style: solid;
    border-color: #a8acac;
    border-width: 1px 1px 0 0;
}

.oz_pager_nav._prev:before {
    margin-left: -3px;
    transform: rotate(-135deg);
}

.oz_pager_nav._next:before {
    margin-left: -7px;
    transform: rotate(45deg);
}

.oz_pager_nav:not(._disabled):hover:before {
    border-color: #000;
}