.custom_input,
.custom_textarea {
    width: 100%;
    font-family: $font_family;
    font-size: 14px;
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 2px;
}

.custom_input:focus,
.custom_textarea:focus {
    border-color: #1d8e7f;
}

.custom_input {
    height: 42px;
    padding: 0 10px;
}

.custom_textarea {
    padding: 10px;
}

.custom_input[disabled],
.custom_textarea[disabled] {
    background: #f7f9f8;
}

.custom_input._error,
.custom_textarea._error {
    border-color: #f00;
    box-shadow: 0 0 5px #f00;
    animation: input_shake 240ms linear;
}

@keyframes input_shake {
    25% { transform: translateX(7px); }
    50% { transform: translateX(-7px); }
    75% { transform: translateX(7px); }
    100% { transform: translateX(-7px); }
}