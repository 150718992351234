.oz_modal {
    font-family: $font_family;
    font-size: 15px;
}

.oz_modal_content {
    padding: 26px;
    background: #fff;
    border: none;
    border-radius: 0;
}

.oz_modal_title {
    margin: 0 0 25px 0;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.3;
}

.oz_modal_control {
    display: flex;
    flex-wrap: wrap;
    margin: 25px 0 0 0;
}

.oz_modal_control:first-child {
    margin-top: 0;
}

.oz_modal_close {
    display: block;
    position: absolute;
    top: 15px;
    right: 15px;
    width: 22px;
    height: 22px;
    padding: 0;
    cursor: pointer;
    background: none;
    border: none;
    outline: none;

    &:before,
    &:after {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 30px;
        height: 2px;
        margin: -1px 0 0 -15px;
        background: $text_color;
    }

    &:before {
        transform: rotate(-45deg);
    }

    &:after {
        transform: rotate(45deg);
    }

    &:hover:before,
    &:hover:after {
        background: $hover_color;
    }
}