.oz_media_upload {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    height: 220px;
    padding: 20px;
    cursor: pointer;
    background-color: #fdfdfd;
    background-image: url('../img/upload.svg');
    background-repeat: no-repeat;
    background-position: 50% 90px;
    background-size: 78px;
    border: 1px solid #C7DBD8;
    transition: background-color 120ms ease-out;
}

.oz_media_upload:hover,
.oz_media_upload._hover {
    background-color: #efefef;
}

.oz_media_upload_input {
    position: absolute;
    visibility: hidden;
}

.oz_media_upload_message {
    margin: 0 0 90px 0;
    font-size: 15px;
}